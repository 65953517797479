import tippy, { followCursor, inlinePositioning } from 'tippy.js'

export default class InteractiveContentTooltip {
    constructor(el) {
        const link = $(el)[0];
        const image = $(el).find('.c-interactive-content__image')[0]
        const container = $(el).parents('.c-interactive-content__sentence')[0]

        // * https://atomiks.github.io/tippyjs/v6/all-props/
        tippy(link, {
            content: image,
            inertia: 'true',
            duration: [600, 300],
            allowHTML: 'true',
            arrow: false,
            zIndex: -1,
            role: 'revealed_background_image',

            // * forces hovered item to remain inside appended container
            appendTo: container,
            placement: 'auto',
            popperOptions: {
                modifiers: [
                    {
                        name: 'preventOverflow',
                        options: {
                            boundary: container,
                            tether: false,
                        },
                    },
                    {
                        name: 'flip',
                        options: {
                            boundary: container
                        }
                    },
                ],
            },

            touch: 'false',
            plugins: [followCursor, inlinePositioning],
            followCursor: 'initial',
            inlinePositioning: true,
        });
    }
}